import React from 'react';
import Skills from '../sections/Skills';

const Learning = () => {
  return (
    <>
      <div className='header'>
        <h1>Tech Stack</h1>
        <h2>.NET | Typescript | Tailwind </h2>
      </div>
        
        <p>
        I have professional experience with .NET Core and Typescript, creating modern 
        backend APIs to be consumed by frameworks including React, Angular, and 
        Next.js (using TypeScript). My strongest skill lies in developing robust APIs but I am equally 
        comfortable with all aspects of modern .NET development including Blazor, Razor Pages, MVC, 
        and Entity Framework. Alongside that, I have experience working on non-web solutions such as creating
        Windows services to automate financial tasks.
        </p>

        <p>
        I am proficient with writing queries and 
        stored procedures in SQL Server, but I generally prefer to work with ORMs for their efficiency 
        and ease of use. 
        </p>

        <p>
          I also enjoy design and practice CSS once in a while. This website was built with custom CSS
          and is mobile friendly. Nowadays though I use Tailwind instead.
        </p>

        <p>
        With these skills I'm able to contribute to efficient, scalable, and 
        maintainable solutions across the full stack.
        </p>
      <Skills />
    </>
  );
};

export default Learning;
